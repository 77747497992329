<template>
  <v-data-table
    :headers="tableHeader"
    :items="formatedMailList"
    :search="search"
    :loading="loading"
    :options="paginationOptions"
    :server-items-length="totalCount"
    @pagination="someChange"
    @click:row="rowClicked"
    item-key="id"
    height="200"
    loading-text="Loading...! Please Wait..."
    no-results-text="No Data Found...!"
    no-data-text="No data available"
    class="elevation-1 mb-5"
  >
    <!-- class="elevation-1" -->
    <template v-slot:top>
      <div class="table-header">
        <h5 class="mr-4">
          {{ $vuetify.lang.t("$vuetify.incoming_emails") }}
        </h5>
        <v-text-field
          v-model="search"
          :placeholder="$vuetify.lang.t('$vuetify.search_here')"
          hide-details
          class="m-0 mx-2 p-0 text-field"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";
import { UTC_TO_LOCAL } from "@/helpers";

export default {
  data() {
    return {
      search: "",
      loading: true,
      emails: [],
      totalCount: 379,
      currentPage: 1,
      limit: 10,
    };
  },
  computed: {
    ...mapState(["user"]),
    paginationOptions() {
      return { page: this.currentPage, itemsPerPage: this.limit };
    },
    formatedMailList() {
      return this.emails
        .map((mail) => {
          const {
            _id,
            subject,
            created_at,
            application_id,
            posted_by: { full_name },
            application: { candidate_id },
          } = mail;

          return {
            id: _id,
            title: subject,
            name: full_name,
            candidate_id,
            application_id,
            date: UTC_TO_LOCAL(created_at, "lll"),
          };
        })
        .filter((mail) =>
          mail.name.toLowerCase().includes(this.search.toLowerCase())
        );
    },
    tableHeader() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.candidate_name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.title"),
          value: "title",
          sortable: true,
        },
        { text: this.$vuetify.lang.t("$vuetify.time_and_date"), value: "date" },
      ];
    },
  },
  methods: {
    async fetchMails() {
      try {
        this.loading = true;
        const { currentPage, limit } = this;

        const resp = await this.axios.get(
          `company/${this.user.company_id}/emails?type=inbound&desc=created_at&p=${currentPage}&limit=${limit}`
        );

        const { count, data } = resp.data;
        this.emails = data;
        this.totalCount = count;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    someChange(options) {
      const { page, itemsPerPage } = options;
      if (page !== this.currentPage || itemsPerPage !== this.limit) {
        this.currentPage = page;
        this.limit = itemsPerPage;
        this.fetchMails();
      }
    },
    rowClicked({ application_id }) {
      this.$router.push(`/application/${application_id}`);
    },
  },
  created() {
    this.fetchMails();
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  background-color: #cbd9e2;
  padding: 1rem;

  .text-field {
    max-width: 200px;
  }
}

h5 {
  font-weight: 500;
}
</style>
