<template>
  <Card :title="$vuetify.lang.t('$vuetify.applicant_sources')" :loading="loading">
    <v-chart class="chart" :option="option" :key="loading" />
  </Card>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { GaugeChart } from "echarts/charts";
import { TooltipComponent, AxisPointerComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([CanvasRenderer, GaugeChart, TooltipComponent, AxisPointerComponent]);

export default {
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "light"
  },
  data() {
    return {
      loading:false,
      option: {
        series: [
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: "#464646"
              }
            },
            axisLine: {
              lineStyle: {
                width: 40
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: 10,
                name: this.$vuetify.lang.t("$vuetify.email"),
                title: {
                  offsetCenter: ["0%", "-30%"]
                },
                detail: {
                  offsetCenter: ["0%", "-18%"]
                }
              },
              {
                value: 25,
                name: this.$vuetify.lang.t("$vuetify.website"),
                title: {
                  offsetCenter: ["0%", "0%"]
                },
                detail: {
                  offsetCenter: ["0%", "12%"]
                }
              },
            ],
            title: {
              fontSize: 14
            },
            detail: {
              width: 40,
              height: 10,
              fontSize: 12,
              color: "auto",
              borderColor: "auto",
              borderRadius: 20,
              borderWidth: 1,
              formatter: "{value}%"
            }
          }
        ]
      }
    };
  },
  methods: {
    async fetchSources() {
      try {
        this.loading = true;
        const resp = await this.axios.get("/application/sources");
        const { website , email } = resp.data.data
        const total = website + email

        this.option.series[0].data[0].value = Math.ceil(( email / total) * 100);
        this.option.series[0].data[1].value = Math.floor(( website / total) * 100);

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    }
  },
  created() {
    this.fetchSources();
  }
};
</script>

<style lang="scss" scoped>
.chart {
  height: 400px;
}
</style>
