/* eslint-disable no-mixed-spaces-and-tabs */
<template>
  <div class="card-item" v-ripple @click="goToRoute(detail.title)">
    <div class="label">
      <v-icon class="mx-2" small>{{ detail.icon }}</v-icon>
      <span>{{ title }}</span>
    </div>
    <h4 :style="{ color: detail.valueColor }">{{ detail.value }}</h4>
    <p v-if="detail.lastPercentage > 0">
      <span :class="`${detail.up ? 'success' : 'error'}--text`">
        <v-icon v-if="detail.up" color="success">mdi-menu-up</v-icon>
        <v-icon v-else color="error">mdi-menu-down</v-icon>
        {{ detail.lastPercentage }}%
      </span>
      {{ detail.lastText }}
    </p>
  </div>
</template>

<script>
import snakeCase from "lodash/snakeCase";
export default {
  name: "dashboard-card",
  props: {
    detail: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      return this.$vuetify.lang.t(`$vuetify.${snakeCase(this.detail.title)}`);
    },
  },
  methods: {
    goToRoute(title) {
      let filter = title;
      if (title.includes("pending")) filter = "pending";
      this.$router.push(`/application?filter=${filter}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-item {
  background-color: #e5e5e5;
  text-align: center;
  padding: 0.7em 0.2em;

  border-radius: 4px;
  cursor: pointer;

  .label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;

    span {
      font-size: 0.7em;
      text-transform: capitalize;
    }
  }
  h4 {
    margin: 0;
    font-weight: bold;
    font-size: 2em;
  }
  p {
    margin: 0.4em 0 0 0;
    line-height: 17px;
    font-size: 0.8em;
    font-weight: 300;
    color: #303753;
  }
}
</style>
