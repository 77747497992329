<template>
  <Card :title="$vuetify.lang.t('$vuetify.published_jobs')" :loading="loading">
    <v-chart class="chart" :key="loading" :option="option" />
  </Card>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TooltipComponent,
  AxisPointerComponent,
  GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

import { COLORS } from "@/constants";

import { mapState } from "vuex";

use([
  CanvasRenderer,
  BarChart,
  TooltipComponent,
  AxisPointerComponent,
  GridComponent,
]);

export default {
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return {
      loading: true,
      labels: [],
      dataSet: [],
    };
  },
  computed: {
    ...mapState(["user"]),
    formattedLabels() {
      return this.labels.map((label) => {
        return label.split(" ").join(" \n");
      });
    },
    option() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.formattedLabels,
            position: "bottom",
            offset: 5,
            nameTextStyle: {
              rotate: 90,
              interval: "auto",
              show: true,
              inside: false,
              showMinLabel: null,
              showMaxLabel: null,
              margin: 8,
              fontSize: 12,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "No. Of Applications",
            type: "bar",
            barWidth: "60%",
            data: this.dataSet,
          },
        ],
      };
    },
  },
  methods: {
    async fetchStats() {
      try {
        const resp = await this.axios.get(
          `/activeJobs?company_id=${this.user.company_id}`
        );

        const activeJobs = resp.data.data;
        activeJobs.forEach((job, index) => {
          const { title, applications } = job;
          this.labels.push(title);
          this.dataSet.push({
            value: applications.length,
            itemStyle: { color: COLORS[index] },
          });
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },

  created() {
    this.fetchStats();
  },
};
</script>

<style lang="scss" scoped>
.chart {
  height: 400px;
}
</style>
