<template>
  <Card :title="$vuetify.lang.t('$vuetify.time_to_hire')">
    <div class="text-center">
      <h1>25</h1>

      <p class="subtitle">
        <v-icon>mdi-clock</v-icon>
        {{ $vuetify.lang.t("$vuetify.days_on_the_average") }}
      </p>
    </div>
  </Card>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.text-center {
  h1 {
    font-size: 5rem;
    font-weight: 900;
  }

  p.subtitle {
    font-size: 1rem;
  }
}
</style>
