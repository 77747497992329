<template>
  <Card
    :title="$vuetify.lang.t('$vuetify.application_status')"
    :loading="statsLoading"
  >
    <ul class="list-app-stats">
      <li>
        <div class="label"><span>Total Applications</span></div>
        <span class="value">{{ statsCount }}</span>
        <span class="stat"></span>
      </li>
      <li v-for="(item, i) in formattedStats" :key="i">
        <div class="label">
          <span class="text-capitalize">{{
            item.title
          }}</span>
        </div>
        <span class="value">{{ item.value }}</span>
        <!-- <span class="stat" :class="`${item.up ? 'success' : 'error'}--text`">
          <v-icon :color="item.up ? 'success' : 'error'">{{
            `mdi-menu-${item.up ? "up" : "down"}`
          }}</v-icon>
          {{ item.percentage }}%</span
        > -->
      </li>
    </ul>
  </Card>
</template>

<script>
import { mapState } from "vuex";
import snakeCase from "lodash/snakeCase";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["statsLoading", "statsCount", "formattedStats"]),
  },
  methods: {
    translateAddress(title) {
      return this.$vuetify.lang.t(`$vuetify.${snakeCase(title)}`);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.list-app-stats {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.85rem;
    font-size: 0.9em;
    position: relative;

    .label {
      width: 60%;

      span {
        display: inline-block;
        background-color: #f3f3f3;
        padding: 0 10px;
        position: relative;
        z-index: 1;
      }

      &::after {
        content: "";
        display: block;
        width: 54%;
        height: 1px;
        background-color: #d7d7d7;
        position: absolute;
        left: 11px;
        top: 16px;
      }
    }
    .value {
      background-color: #d7d8da;
      padding: 2px 10px;
      border-radius: 4px;
      font-weight: 500;
      width: 85px;
      text-align: center;
      margin-right: 10px;
    }
  }
}

.v-application--is-rtl {
  li {
    .label {
      &::after {
        right: 1rem;
        left: unset;
      }

      .value {
        display: inline-block;
      }
    }
  }
}
</style>
