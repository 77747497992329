<template>
  <Card
    :title="$vuetify.lang.t('$vuetify.new_applications')"
    :loading="loading"
  >
    <v-chart class="chart" :option="option" />
  </Card>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import { TooltipComponent, AxisPointerComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

import { mapState } from "vuex";

use([CanvasRenderer, LineChart, TooltipComponent, AxisPointerComponent]);

export default {
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return {
      loading: true,
      appData: [],
    };
  },
  computed: {
    ...mapState(["user"]),
    option() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: [
          {
            type: "category",
            data: this.appData.map((a) => a.month),
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Number of Applications",
            type: "line",
            barWidth: "60%",
            data: this.appData.map((a) => a.count),
          },
        ],
      };
    },
  },
  methods: {
    async fetchApplications() {
      try {
        const resp = await this.axios.get(
          `/biyearly/applicationCount?company_id=${this.user.company_id}`
        );

        this.appData = resp.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.fetchApplications();
  },
};
</script>

<style lang="scss" scoped>
.chart {
  height: 400px;
}
</style>
