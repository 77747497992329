<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" :md="isAdmin ? '8' : '12'">
        <template v-if="isAdmin">
          <loader
            :loading="statsLoading"
            height="220px"
            :overlay="false"
            center
          >
            <div class="card-lists mb-10">
              <DashboardCard
                v-for="(detail, index) in DASHBOARD_CARDS"
                :key="index"
                :detail="detail"
              />
            </div>
          </loader>
        </template>

        <UpcommingInterviews />

        <IncommingEmail v-if="isAdmin" />

        <PublishedJob />

        <NewApplications v-if="isAdmin" />
      </v-col>
      <v-col cols="12" sm="12" md="4" v-if="isAdmin">
        <AIEngine v-if="SHOW_AI_SCORE" />
        <Funnel />
        <TimeToHire />
        <ApplicationSource />
        <ApplicationStatus />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DashboardCard from "@/components/dashboard-card";
import UpcommingInterviews from "@/components/datatables/upcomming-interviews";
import IncommingEmail from "@/components/datatables/incomming-email";

import PublishedJob from "@/components/dashboard-widgets/published-job";
import NewApplications from "@/components/dashboard-widgets/new-applications";

import AIEngine from "@/components/dashboard-widgets/ai-engine";
import Funnel from "@/components/dashboard-widgets/funnel";
import TimeToHire from "@/components/dashboard-widgets/time-to-hire";
import ApplicationSource from "@/components/dashboard-widgets/application-source";
import ApplicationStatus from "@/components/dashboard-widgets/application-status";

import { mapState, mapActions, mapGetters } from "vuex";
import userRestriction from "@/mixins/user-restriction.js";
export default {
  name: "Dashboard",
  mixins: [userRestriction],
  components: {
    DashboardCard,
    UpcommingInterviews,
    IncommingEmail,
    AIEngine,
    Funnel,
    TimeToHire,
    ApplicationSource,
    ApplicationStatus,
    PublishedJob,
    NewApplications,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["user", "statsLoading"]),
    ...mapGetters(["DASHBOARD_CARDS", "SHOW_AI_SCORE"]),
  },
  methods: {
    ...mapActions(["FETCH_STATS"]),
  },
  created() {
    this.FETCH_STATS();
  },
};
</script>

<style lang="scss" scoped>
.card-lists {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  grid-gap: 1rem;
}
</style>
