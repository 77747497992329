<template>
  <Card :title="$vuetify.lang.t('$vuetify.talent_funnel')">
    <v-chart class="chart" :option="option" v-if="false" />

    <div class="grid">
      <FunnelSvg class="svg" ref="funnelSvg" v-if="false" />
      <img src="@/assets/funnel.png" ref="funnelSvg" class="img-fluid" alt="" />

      <ul :key="reRender" v-if="!statsLoading">
        <li
          v-for="(label, index) in FUNNEL_DATA_SET"
          :key="index"
          :style="`height:${calculateHeight(index)}px`"
        >
          <p class="title" v-html="translateTitle(label.name)"></p>
          <p class="value">{{ label.value }}</p>
        </li>
      </ul>

      <loader
        v-else
        :loading="statsLoading"
        :overlay="false"
        class="loading-container"
        loaderColor="#fff"
      >
      </loader>
    </div>
  </Card>
</template>

<script>
import FunnelSvg from "@/components/svg/funnel_svg";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { FunnelChart } from "echarts/charts";
import { TooltipComponent, LegendComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import snakeCase from "lodash/snakeCase";

use([CanvasRenderer, FunnelChart, TooltipComponent, LegendComponent]);

import { mapState, mapGetters } from "vuex";
export default {
  components: {
    VChart,
    FunnelSvg,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return {
      liHeight: 100,
      funnelHeight: 400,
      reRender: false,
    };
  },
  computed: {
    ...mapState(["statsLoading"]),
    ...mapGetters(["FUNNEL_DATA_SET"]),
    option() {
      return {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c}",
        },

        series: [
          {
            name: "Name",
            type: "funnel",
            left: "10%",
            top: 50,
            //x2: 80,
            bottom: 60,
            width: "80%",
            // height: {totalHeight} - y - y2,
            min: 0,
            max: 100,
            minSize: "1%",
            maxSize: "100%",
            sort: "descending",
            gap: 2,
            label: {
              show: true,
              position: "inside",
            },
            labelLine: {
              length: 10,
              lineStyle: {
                width: 1,
                type: "solid",
              },
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1,
            },
            emphasis: {
              label: {
                fontSize: 20,
              },
            },
            data: this.FUNNEL_DATA_SET,
          },
        ],
      };
    },
  },
  methods: {
    calculateHeight(index) {
      if (index === 0) {
        return this.liHeight;
      } else if (index === 1) {
        return this.liHeight - 5;
      } else if (index === 3 || index === 2) {
        return this.liHeight - 15;
      } else {
        return this.liHeight - 10;
      }
    },
    setListHeight() {
      const funnel = this.$refs.funnelSvg;
      this.funnelHeight = funnel.clientHeight;
      this.liHeight = funnel.clientHeight / 5;
    },
    translateTitle(title) {
      return this.$vuetify.lang.t(`$vuetify.${snakeCase(title)}`);
    },
  },
  mounted() {
    this.setListHeight();
  },
  watch: {
    FUNNEL_DATA_SET() {
      this.setListHeight();
      setTimeout(() => {
        this.reRender = !this.reRender;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  min-height: 400px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.v-application--is-rtl .grid {
  padding-right: 3rem;
  padding-left: 0;
}
.grid {
  padding-left: 3rem;
  padding-right: 0;
  position: relative;
  text-align: center;

  ul {
    padding: 0;
    list-style: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 3rem;

    li {
      height: 40px;
      display: flex;
      align-items: center;

      p.title {
        width: 40px;
        font-size: 1rem !important;
        line-height: 1.4;
        text-transform: capitalize;
      }

      p.value {
        flex: 1;
        text-align: center;
        color: #fff;
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
  }
}

.loading-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
