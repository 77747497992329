<template>
  <div class="scrollable-container" :style="styles">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styles() {
      if (this.horizontal) {
        return "overflow-x: scroll; padding-bottom : 1rem";
      } else {
        return "overflow-y: scroll; padding-right : 1rem";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollable-container {
  margin: 0;
  overflow: hidden;

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #cbcbcb;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3ca3d6;
    border-radius: 8px;
  }
}
</style>
