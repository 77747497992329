<template>
  <Card
    :title="$vuetify.lang.t('$vuetify.top_matches_by_ai_engine')"
    :badge="`Top ${candidates.length}`"
    headerLink="/application?sortBy=ai"
    hideDownBtn
    :loading="loading"
  >
    <div class="top-matches-wrap">
      <scrollable horizontal>
        <ul>
          <li v-for="candidate in candidates" :key="candidate._id">
            <div class="chart">
              <v-progress-circular
                :rotate="360"
                :size="55"
                :width="5"
                :value="candidate.ai_score || 0"
                color="accent"
              >
                {{ candidate.ai_score || 0 }}%
              </v-progress-circular>
            </div>
            <div class="information">
              <div class="rating">
                <v-icon color="warning" small v-for="n in 5" :key="n">{{
                  stars(n, candidate.ai_score || 0)
                }}</v-icon>
              </div>
              <p>
                {{ candidate.candidate.first_name }} <br />
                {{ candidate.job_post.title }}
              </p>
              <router-link :to="`/application/${candidate._id}`"
                >view details <v-icon>mdi-menu-right</v-icon>
              </router-link>
            </div>
          </li>
        </ul>
      </scrollable>
    </div>
  </Card>
</template>

<script>
import scrollable from "@/components/scrollable/scrollable";
export default {
  components: {
    scrollable,
  },
  data() {
    return {
      loading: false,
      candidates: [],
    };
  },
  methods: {
    async fetchAIScore() {
      try {
        this.loading = true;
        const resp = await this.axios.get("/top/ai_score");
        const { data } = resp.data;
        this.candidates = data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    stars(index, score = 0) {
      return index <= Math.floor(score / 20) ? "mdi-star" : "mdi-star-outline";
    },
  },
  created() {
    this.fetchAIScore();
  },
};
</script>

<style lang="scss" scoped>
.top-matches-wrap {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;

    li {
      display: flex;
      align-items: center;
      border-right: 1px solid #d3d4d6;
      flex: 0 0 auto;
      max-width: 180px;
      padding-right: 10px;
      padding-left: 10px;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        border-right: none;
        padding-right: 0;
      }

      .chart {
        flex-basis: 30%;
        img {
          max-width: 100%;
        }
      }
      .information {
        padding-left: 10px;
        flex-basis: 70%;
        .rating {
          margin-bottom: 4px;
          img {
            width: 16px;
          }
        }
        p {
          margin: 0;
          line-height: 1.3em;
          font-size: 0.8em;
        }

        a {
          color: #707a84;
          font-size: 0.8em;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
